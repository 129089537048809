"use strict";

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.object.define-property");

require("core-js/modules/web.dom-collections.for-each");

require("core-js/modules/web.timers");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _linkedListJs = _interopRequireDefault(require("@magic-power-research/linked-list-js"));

var _renderersFactory = _interopRequireDefault(require("../renderers/renderersFactory"));

var _helper = _interopRequireDefault(require("../lib/helper"));

var _resources = _interopRequireDefault(require("../lib/resources"));

var _interpreter = _interopRequireDefault(require("../lib/JS-Interpreter/interpreter"));

var _requestAnimationFrame = require("../lib/requestAnimationFrame");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SpecialEngine = function SpecialEngine(element, options) {
  var renderMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'canvas';

  _classCallCheck(this, SpecialEngine);

  /**
   * 开始时间
   * @private @type {number}
   */
  var _startTime;
  /**
   * 暂停时间
   * @private @type {number}
   */


  var _pauseTime = 0;
  /**
   * 弹幕缓冲区
   * @private @type {LinkedList}
   */

  var _bulletScreenBuffer = new _linkedListJs["default"]();
  /**
   * 实时弹幕列表
   * @private @type {LinkedList}
   */


  var _realTimeBulletScreens = new _linkedListJs["default"]();
  /**
   * 延迟弹幕总数
   * @private @type {number}
   */


  var _delayBulletScreenCount = 0;
  /**
   * 延迟（单位：毫秒）
   * @private @type {number}
   */

  var _delay = 0;
  /**
   * 播放标志
   * @private @type {boolean}
   */

  var _playing;
  /**
   * requestAnimationFrame 句柄
   */


  var _requestAnimationFrameHandel = null;
  /**
   * 刷新频率
   * @private @type {number}
   */

  var _refreshRate = 0.06;
  /**
   * 上一次刷新时间
   * @private @type {number}
   */

  var _lastRefreshTime;
  /**
   * 全局选项
   * @private @type {openBSE~specialOptions}
   */


  var _options;
  /**
   * 默认全局变量
   * @private @readonly
   */


  var _defaultOptions = {
    /** 播放速度(倍数) */
    playSpeed: 1,

    /** 时间基准 */
    clock: function clock(time) {
      return (0, _requestAnimationFrame.performance_now)() - _startTime;
    },

    /** 缩放比例 */
    scaling: 1,

    /** 超时丢弃 */
    timeOutDiscard: true,

    /** 弹幕不透明度 */
    opacity: 1
  };
  /**
   * 全局选项类型
   * @private @readonly
   */

  var _optionsType = {
    playSpeed: 'number',
    clock: 'function',
    scaling: 'number',
    timeOutDiscard: 'boolean',
    opacity: 'number'
  };
  /**
   * 默认弹幕数据
   * @private @readonly
   */

  var _defaultBulletScreen = {
    /** 弹幕文本 */
    text: null,

    /** 是否允许丢弃 */
    canDiscard: true,

    /** 弹幕进入时间 */
    startTime: null,

    /** 弹幕退出时间 */
    endTime: null,

    /** 弹幕渲染代码 */
    renderCode: null
  };
  /** 
   * 默认弹幕样式 
   * @private @readonly
   * */

  var _defaultStyle = {
    /** 弹幕文本 */
    text: null,

    /** 阴影的模糊级别，0为不显示阴影 */
    shadowBlur: 2,

    /** 字体粗细 */
    fontWeight: '600',

    /** 字体系列 */
    fontFamily: 'sans-serif',

    /** 字体大小（单位：像素） */
    size: 25,

    /** 外框颜色 */
    boxColor: null,

    /** 字体颜色 */
    color: 'white',

    /** 描边颜色 */
    borderColor: null,

    /** 变换 */
    transform: null
  };
  /**
   * 弹幕数据类型
   * @private @readonly
   */

  var _bulletScreenType = {
    text: 'string',
    canDiscard: 'boolean',
    startTime: 'number',
    endTime: 'number',
    renderCode: 'string'
  };
  /** 
   * 默认弹幕样式数据类型 
   * @private @readonly
   * */

  var _defaultStyleType = {
    /** 弹幕文本 */
    text: 'string',

    /** 阴影的模糊级别，0为不显示阴影 */
    shadowBlur: 'number',

    /** 字体粗细 */
    fontWeight: ['string', 'number'],

    /** 字体系列 */
    fontFamily: 'string',

    /** 字体大小（单位：像素） */
    size: 'number',

    /** 外框颜色 */
    boxColor: ['string', 'null'],

    /** 字体颜色 */
    color: 'string',

    /** 描边颜色 */
    borderColor: ['string', 'null'],

    /** 变换 */
    transform: ['string', 'null']
  };
  _options = _helper["default"].setValues(options, _defaultOptions, _optionsType);
  var _elementSize = {
    width: element.clientWidth / _options.scaling,
    height: element.clientHeight / _options.scaling
  };

  var _oldDevicePixelRatio = _helper["default"].getDevicePixelRatio();

  var _oldScaling = _options.scaling;
  var _oldClientWidth = element.clientWidth;
  var _oldClientHeight = element.clientHeight;
  var _oldOpacity = _options.opacity;
  var renderersFactory = new _renderersFactory["default"](element, _options, _elementSize);

  var _renderer = renderersFactory.getSpecialRenderer(renderMode);

  setInterval(setSize, 100);
  /**
   * 设置或获取全局选项
   * @private
   **/

  Object.defineProperty(this, 'options', {
    get: function get() {
      return _helper["default"].clone(_options);
    },
    set: function set(options) {
      _options = _helper["default"].setValues(options, _options, _optionsType, false);

      if (_oldOpacity != _options.opacity) {
        _oldOpacity = _options.opacity;

        _renderer.setOpacity();
      }
    }
  });
  /**
   * 添加弹幕到弹幕列表。
   * @description 添加弹幕到弹幕列表。由于弹幕在屏幕上出现过后，弹幕引擎将从列表中彻底删除此弹幕。所以，在改变播放进度时，可能需要先[清空弹幕列表]{@link openBSE.BulletScreenEngine#cleanBulletScreenList}，然后重新加载此播放进度以后的弹幕。
   * @param {openBSE~SpecialBulletScreen} bulletScreen - 单条弹幕数据：一个 {@link openBSE~SpecialBulletScreen} 结构。
   * @throws {TypeError} 传入的参数错误时引发错误。请参阅 MDN [TypeError]{@link https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/TypeError} 。
   */

  this.add = function (bulletScreen) {
    _defaultBulletScreen.startTime = _options.clock();
    bulletScreen = _helper["default"].setValues(bulletScreen, _defaultBulletScreen, _bulletScreenType);
    var newNode = new _linkedListJs["default"].node(bulletScreen);

    _bulletScreenBuffer.forEach(function (node) {
      var lastBulletScreen = node.element;
      if (bulletScreen.startTime > lastBulletScreen.startTime) return {
        add: {
          addToUp: true,
          node: newNode
        },
        stop: true
      };
    }, true);

    if (newNode.linkedList === null) _bulletScreenBuffer.push(newNode, false);
  };
  /**
   * 开始播放弹幕。
   */


  this.play = function () {
    if (!_playing) {
      if (!_startTime) _startTime = (0, _requestAnimationFrame.performance_now)();
      if (_pauseTime) _startTime += _options.clock() - _pauseTime;
      _lastRefreshTime = null;
      _playing = true;
      _requestAnimationFrameHandel = (0, _requestAnimationFrame.requestAnimationFrame)(refresh);
    }
  };
  /**
   * 暂停播放弹幕。
   * @description 暂停播放弹幕。暂停播放弹幕是指弹幕播放暂停，所有未出现的弹幕将停止出现，已出现的弹幕停止运动，停止消失。
   */


  this.pause = function () {
    if (_playing) {
      _pauseTime = _options.clock();
      _playing = false;
      (0, _requestAnimationFrame.cancelAnimationFrame)(_requestAnimationFrameHandel);
    }
  };
  /**
   * 清空弹幕缓冲区。
   * @description 清空弹幕列表，但屏幕上已经出现的弹幕不会被清除。
   */


  this.cleanBuffer = function () {
    _bulletScreenBuffer.clean();
  };
  /**
   * 清空屏幕内容。
   * @description 清空屏幕内容。清空屏幕上已经出现的弹幕，不包括弹幕列表中的弹幕。
   */


  this.cleanScreen = function () {
    _realTimeBulletScreens.clean();

    _renderer.cleanScreen();
  };
  /**
   * 停止播放弹幕。
   * @description 停止播放弹幕。停止播放弹幕是指停止播放弹幕，默认[时间基准（options.clock）]{@link openBSE~BulletScreenStyle}归零，并[清空弹幕列表]{@link openBSE.BulletScreenEngine#cleanBulletScreenList}、[清空屏幕内容]{@link openBSE.BulletScreenEngine#cleanScreen}。
   */


  this.stop = function () {
    if (_playing) {
      this.pause();
    }

    this.cleanBuffer();
    this.cleanScreen();
    _pauseTime = 0;
    _startTime = null;
  };
  /**
   * 获取或设置弹幕可见性。
   * @private
   */


  Object.defineProperty(this, 'visibility', {
    get: function get() {
      return _renderer.getVisibility();
    },
    set: function set(visibility) {
      if (visibility) _renderer.show();else _renderer.hide();
    }
  });
  /**
   * 获取渲染模式。
   * @private
   */

  Object.defineProperty(this, 'renderMode', {
    get: function get() {
      return renderMode;
    }
  });
  /**
   * 获取播放状态。
   * @private
   */

  Object.defineProperty(this, 'playState', {
    get: function get() {
      return _playing;
    }
  });
  /**
  * 获取调试信息。
  * @private
  */

  Object.defineProperty(this, 'debugInfo', {
    get: function get() {
      return {
        time: _playing ? _options.clock() : _pauseTime,
        realTimeBulletScreenCount: _realTimeBulletScreens.length,
        bufferBulletScreenCount: _bulletScreenBuffer.length,
        delay: _delay,
        delayBulletScreenCount: _delayBulletScreenCount,
        fps: _playing ? Math.floor(_refreshRate * 1000) : 0
      };
    }
  });
  /**
   * 刷新弹幕函数
   * @private
   */

  function refresh(timestamp) {
    var nowTime = timestamp;
    if (_lastRefreshTime != null) _refreshRate = 1 / (nowTime - _lastRefreshTime);
    _lastRefreshTime = nowTime;
    addRealTimeBulletScreens();
    moveRealTimeBulletScreen();

    _renderer.draw();

    if (_playing) _requestAnimationFrameHandel = (0, _requestAnimationFrame.requestAnimationFrame)(refresh);
  }
  /**
   * 移动弹幕函数
   * @private
   */


  function moveRealTimeBulletScreen() {
    _realTimeBulletScreens.forEach(function (node) {
      var realTimeBulletScreen = node.element;

      var nowTime = _options.clock();

      if (realTimeBulletScreen.bulletScreen.endTime > nowTime) {
        try {
          var interpreter = new _interpreter["default"](realTimeBulletScreen.bulletScreen.renderCode, function (interpreter, scope) {
            return InterpreterInit(interpreter, scope, realTimeBulletScreen);
          });
          interpreter.run();
        } catch (ex) {
          console.error(ex);

          _renderer["delete"](realTimeBulletScreen);

          return {
            remove: true
          };
        }
      } else {
        _renderer["delete"](realTimeBulletScreen);

        return {
          remove: true
        };
      }
    }, true);
  }
  /**
   * 添加弹幕到实时弹幕列表
   * @private
   */


  function addRealTimeBulletScreens() {
    if (_realTimeBulletScreens.length === 0) _delay = 0;
    var times = Math.floor(_refreshRate * 2000);

    do {
      var node = _bulletScreenBuffer.pop(false, false);

      if (node === null) return;
      var bulletScreen = node.element;

      var nowTime = _options.clock();

      if (bulletScreen.startTime > nowTime) return;

      if (!_options.timeOutDiscard || !bulletScreen.canDiscard || bulletScreen.startTime > nowTime - Math.floor(1 / _refreshRate) * 60) {
        getRealTimeBulletScreen(bulletScreen);
      } else _delayBulletScreenCount++;

      node.remove();
      times--;
    } while (_realTimeBulletScreens.length === 0 || times > 0);
  }
  /**
   * 生成实时弹幕对象
   * @private
   * @param {openBSE~BulletScreen} bulletScreen - 弹幕的链表节点
   */


  function getRealTimeBulletScreen(bulletScreen) {
    var realTimeBulletScreen = {};
    realTimeBulletScreen.bulletScreen = bulletScreen;
    realTimeBulletScreen.style = _helper["default"].clone(_defaultStyle);
    realTimeBulletScreen.style.text = bulletScreen.text;
    var newNode = new _linkedListJs["default"].node(realTimeBulletScreen);

    _realTimeBulletScreens.push(newNode, false);

    _renderer.creat(realTimeBulletScreen);
  }
  /**
   * 解释器加载
   * @private
   * @param {*} interpreter - 解释器对象
   * @param {*} scope - scope
   * @param {*} realTimeBulletScreen - 弹幕对象
   */


  function InterpreterInit(interpreter, scope, realTimeBulletScreen) {
    interpreter.setProperty(scope, 'time', _options.clock());
    interpreter.setProperty(scope, 'startTime', realTimeBulletScreen.startTime);
    interpreter.setProperty(scope, 'endTime', realTimeBulletScreen.endTime);
    interpreter.setProperty(scope, 'elementWidth', _elementSize.width);
    interpreter.setProperty(scope, 'elementHeight', _elementSize.height);
    interpreter.setProperty(scope, 'scaling', devicePixelRatio * options.scaling);
    interpreter.setProperty(scope, 'setStyle', interpreter.createNativeFunction(function (obj) {
      return setStyle(obj.properties, realTimeBulletScreen);
    }));
  }
  /**
   * 设置弹幕样式
   * @private
   * @param {*} style - 弹幕样式
   * @param {*} realTimeBulletScreen - 弹幕对象
   */


  function setStyle(style, realTimeBulletScreen) {
    if (_helper["default"].isEmpty(style) || style === {}) return;
    var oldStyle = realTimeBulletScreen.style;
    realTimeBulletScreen.style = _helper["default"].setValues(style, realTimeBulletScreen.style, _defaultStyleType, true);
    if (_helper["default"].shallowEqual(oldStyle, realTimeBulletScreen.style)) return;

    _renderer.refresh(oldStyle, realTimeBulletScreen);
  }
  /**
   * 设置尺寸
   * @private
   */


  function setSize() {
    var devicePixelRatio = _helper["default"].getDevicePixelRatio();

    if (_oldDevicePixelRatio != devicePixelRatio || _oldClientWidth != element.clientWidth || _oldClientHeight != element.clientHeight || _oldScaling != _options.scaling) {
      _oldScaling = _options.scaling;
      _elementSize.width = element.clientWidth / _options.scaling;
      _elementSize.height = element.clientHeight / _options.scaling;
      _oldClientWidth = element.clientWidth;
      _oldClientHeight = element.clientHeight;
      _oldDevicePixelRatio = devicePixelRatio;

      _renderer.setSize();

      if (!_playing) _renderer.draw();
    }
  }
};

var _default = SpecialEngine;
exports["default"] = _default;