"use strict";

require("core-js/modules/es.symbol");

require("core-js/modules/es.symbol.description");

require("core-js/modules/es.symbol.iterator");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.date.to-string");

require("core-js/modules/es.object.create");

require("core-js/modules/es.object.get-prototype-of");

require("core-js/modules/es.object.set-prototype-of");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.reflect.construct");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.iterator");

require("core-js/modules/web.dom-collections.for-each");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _specialBaseRenderer = _interopRequireDefault(require("./specialBaseRenderer"));

var _browserNotSupportError = _interopRequireDefault(require("../errors/browserNotSupportError"));

var _linkedListJs = _interopRequireDefault(require("@magic-power-research/linked-list-js"));

var _helper = _interopRequireDefault(require("../lib/helper"));

var _transformFunctionsInterpreter2 = _interopRequireDefault(require("../lib/transformFunctionsInterpreter"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf(subClass, superClass); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _possibleConstructorReturn(self, call) { if (call && (_typeof(call) === "object" || typeof call === "function")) { return call; } return _assertThisInitialized(self); }

function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }

/**
 * Canvas 渲染器类
 */
var SpecialCanvasRenderer = function (_SpecialBaseRenderer) {
  _inherits(SpecialCanvasRenderer, _SpecialBaseRenderer);

  var _super = _createSuper(SpecialCanvasRenderer);

  /**
   * 实例化一个 Canvas 渲染器类
   * @param {object} element - Element 元素
   * @param {openBSE~Options} options - 全局选项
   * @param {object} elementSize - 元素大小
   * @throws {openBSE.BrowserNotSupportError} 浏览器不支持特定渲染模式时引发错误
   */
  function SpecialCanvasRenderer(element, options, elementSize) {
    var _this;

    _classCallCheck(this, SpecialCanvasRenderer);

    /**
     * 屏幕上的弹幕
     * @private @type {LinkedList}
     */
    var _bulletScreensOnScreen = new _linkedListJs["default"]();
    /**
     * transform-functions 解释器
     */


    var _transformFunctionsInterpreter = new _transformFunctionsInterpreter2["default"]();
    /**
     * DPI 缩放比例（倍数）
     * @private @type {number}
     */


    var _devicePixelRatio = _helper["default"].getDevicePixelRatio(true);

    _devicePixelRatio *= options.scaling;
    supportCheck();
    /**
     * 画布元素
     * @private @type {Element}
     */

    var _canvas = init();

    _this = _super.call(this, _canvas, options, elementSize);
    /**
     * 清除屏幕内容
     * @function
     * @override
     */

    _this.cleanScreen = function () {
      _bulletScreensOnScreen.clean();

      _canvas.getContext('2d').clearRect(0, 0, _canvas.width, _canvas.height);
    };
    /**
     * 绘制函数
     * @override
     */


    _this.draw = function () {
      var canvasContext = _canvas.getContext('2d');

      canvasContext.clearRect(0, 0, _canvas.width, _canvas.height);

      _bulletScreensOnScreen.forEach(function (node) {
        var realTimeBulletScreen = node.element;
        canvasContext.drawImage(realTimeBulletScreen.hideCanvas, 0, 0);
      }, true);
    };
    /**
     * 刷新弹幕样式 
     * @override
     * @param {object} realTimeBulletScreen - 实时弹幕对象
    */


    _this.refresh = function (oldStyle, realTimeBulletScreen) {
      if (oldStyle === null || oldStyle.text != realTimeBulletScreen.style.text || oldStyle.shadowBlur != realTimeBulletScreen.style.shadowBlur || oldStyle.fontWeight != realTimeBulletScreen.style.fontWeight || oldStyle.fontFamily != realTimeBulletScreen.style.fontFamily || oldStyle.size != realTimeBulletScreen.style.size || oldStyle.boxColor != realTimeBulletScreen.style.boxColor || oldStyle.color != realTimeBulletScreen.style.color || oldStyle.borderColor != realTimeBulletScreen.style.borderColor) drawHideTextCanvas(realTimeBulletScreen);

      if (oldStyle === null || oldStyle.transform != realTimeBulletScreen.style.transform) {
        var hideCanvas = document.createElement('canvas');
        var hideCanvasContext = hideCanvas.getContext('2d');
        hideCanvas.width = _canvas.width;
        hideCanvas.height = _canvas.height;
        setTransform(_transformFunctionsInterpreter.toObject(realTimeBulletScreen.style.transform), hideCanvasContext, realTimeBulletScreen.hideTextCanvas);
        realTimeBulletScreen.hideCanvas = hideCanvas;
      }
    };
    /**
     * 创建弹幕元素
     * @override
     * @param {object} realTimeBulletScreen - 实时弹幕对象
     */


    _this.creat = function (realTimeBulletScreen) {
      this.refresh(null, realTimeBulletScreen);
      realTimeBulletScreen.linkedListNode = new _linkedListJs["default"].node(realTimeBulletScreen);

      _bulletScreensOnScreen.push(realTimeBulletScreen.linkedListNode, false);
    };
    /**
     * 删除弹幕元素
     * @override
     * @param {object} realTimeBulletScreen - 实时弹幕对象
     */


    _this["delete"] = function (realTimeBulletScreen) {
      return realTimeBulletScreen.linkedListNode.remove();
    };

    var _setSize = _this.setSize;
    /**
     * 设置尺寸
     * @override
     */

    _this.setSize = function () {
      _setSize();

      _devicePixelRatio = _helper["default"].getDevicePixelRatio();
      _devicePixelRatio *= options.scaling;
      _canvas.width = elementSize.width * _devicePixelRatio;
      _canvas.height = elementSize.height * _devicePixelRatio;
    };

    function drawHideTextCanvas(realTimeBulletScreen) {
      var hideTextCanvas = document.createElement('canvas');
      var hideTextCanvasContext = hideTextCanvas.getContext('2d');
      hideTextCanvasContext.font = "".concat(realTimeBulletScreen.style.fontWeight, " ").concat(realTimeBulletScreen.style.size * _devicePixelRatio, "px ").concat(realTimeBulletScreen.style.fontFamily);
      realTimeBulletScreen.width = hideTextCanvasContext.measureText(realTimeBulletScreen.style.text).width;
      hideTextCanvas.width = realTimeBulletScreen.width + 8 * _devicePixelRatio;
      hideTextCanvas.height = (realTimeBulletScreen.style.size + 8) * _devicePixelRatio;
      hideTextCanvasContext.shadowColor = 'black';
      hideTextCanvasContext.font = "".concat(realTimeBulletScreen.style.fontWeight, " ").concat(realTimeBulletScreen.style.size * _devicePixelRatio, "px ").concat(realTimeBulletScreen.style.fontFamily);
      var textX = 4 * _devicePixelRatio;
      var textY = (4 + realTimeBulletScreen.style.size * 0.8) * _devicePixelRatio;

      if (realTimeBulletScreen.style.color != null) {
        hideTextCanvasContext.shadowBlur = (realTimeBulletScreen.style.shadowBlur + 0.5) * _devicePixelRatio;
        hideTextCanvasContext.fillStyle = realTimeBulletScreen.style.color;
        hideTextCanvasContext.fillText(realTimeBulletScreen.style.text, textX, textY);
      }

      if (realTimeBulletScreen.style.borderColor != null) {
        hideTextCanvasContext.shadowBlur = 0;
        hideTextCanvasContext.lineWidth = 0.5 * _devicePixelRatio;
        hideTextCanvasContext.strokeStyle = realTimeBulletScreen.style.borderColor;
        hideTextCanvasContext.strokeText(realTimeBulletScreen.style.text, textX, textY);
      }

      if (realTimeBulletScreen.style.boxColor != null) {
        hideTextCanvasContext.shadowBlur = 0;
        hideTextCanvasContext.lineWidth = _devicePixelRatio;
        hideTextCanvasContext.strokeStyle = realTimeBulletScreen.style.boxColor;
        hideTextCanvasContext.strokeRect(_devicePixelRatio, _devicePixelRatio, hideTextCanvas.width - _devicePixelRatio, hideTextCanvas.height - _devicePixelRatio);
      }

      realTimeBulletScreen.hideTextCanvas = hideTextCanvas;
    }

    function setTransform(transformObject, canvasContext, textCanvas) {
      var width = textCanvas.width,
          height = textCanvas.height;
      var tx = 0,
          ty = 0;

      if (transformObject.translate) {
        tx = transformObject.translate.tx.value;
        ty = transformObject.translate.ty.value;
      }

      if (transformObject.translateX) tx = transformObject.translate.t.value;
      if (transformObject.translateY) ty = transformObject.translate.t.value;

      if (transformObject.rotate) {
        var halfWidth = width / 2,
            halfHeight = height / 2;
        canvasContext.translate(tx + halfWidth, ty + halfHeight);
        tx = -halfWidth;
        ty = -halfHeight;
        canvasContext.rotate(_transformFunctionsInterpreter2["default"].getAngleValue(transformObject.rotate.a));
      }

      if (transformObject.matrix) {
        canvasContext.transform(transformObject.matrix.a.value, transformObject.matrix.b.value, transformObject.matrix.c.value, transformObject.matrix.d.value, transformObject.matrix.tx.value, transformObject.matrix.ty.value);
      }

      canvasContext.drawImage(textCanvas, tx, ty, width, height);
    }
    /**
     * 添加Canvas
     * @private
     * @returns {Element} 画布对象
     */


    function init() {
      var canvas = document.createElement('canvas');

      _helper["default"].cleanElement(element);

      element.appendChild(canvas);
      canvas.width = elementSize.width * _devicePixelRatio;
      canvas.height = elementSize.height * _devicePixelRatio;
      return canvas;
    }
    /**
     * 浏览器支持检测
     * @private
     * @throws {openBSE.BrowserNotSupportError} 浏览器不支持特定渲染模式时引发错误
     */


    function supportCheck() {
      var canvas = document.createElement('canvas');
      if (typeof canvas.getContext != 'function') throw new _browserNotSupportError["default"]('Canvas');
      var context = canvas.getContext('2d');
      if (context === null) throw new _browserNotSupportError["default"]('Canvas 2D');
      if (typeof context.fillText != 'function') throw new _browserNotSupportError["default"]('Canvas 2D fillText Function');
    }

    return _this;
  }

  return SpecialCanvasRenderer;
}(_specialBaseRenderer["default"]);

var _default = SpecialCanvasRenderer;
exports["default"] = _default;