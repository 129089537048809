"use strict";

require("core-js/modules/es.symbol");

require("core-js/modules/es.symbol.description");

require("core-js/modules/es.symbol.iterator");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.from");

require("core-js/modules/es.array.is-array");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.date.to-string");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.define-property");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.parse-float");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.iterator");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _helper = _interopRequireDefault(require("./helper"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

var TransformFunctionsInterpreter = function () {
  function TransformFunctionsInterpreter() {
    _classCallCheck(this, TransformFunctionsInterpreter);

    var functionStartCharRegex = /^[_a-zA-Z]$/;
    var functionCharRegex = /^\w$/;
    var ParameterChar = /^[\-.%0-9a-zA-Z]$/;
    var valueRegex = /^-?\d+(\.\d+)?/;
    var unitRegex = /[a-zA-Z]*$/;
    var numberRegex = /^-?\d+(\.\d+)?$/;
    var angleRegex = /^(-?\d+(\.\d+)?(deg)|(grad)|(rad)|(turn))|0$/;
    var lengthRegex = /^(\d+(\.\d+)?px)|0$/;

    this.toObject = function (transformFunctionsCode) {
      if (_helper["default"].isEmpty(transformFunctionsCode)) return {};
      var states = 0;
      var object = {};
      var functionName = null;
      var parameter = null;

      var _iterator = _createForOfIteratorHelper(transformFunctionsCode),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _char = _step.value;

          switch (states) {
            case 0:
              if (functionStartCharRegex.test(_char)) {
                functionName = _char;
                states = 1;
              } else if (_char != ' ') throw new SyntaxError();

              break;

            case 1:
              if (functionCharRegex.test(_char)) {
                functionName = functionName.concat(_char);
              } else if (_char === '(') {
                if (typeof object[functionName] != 'undefined') throw new SyntaxError();
                object[functionName] = [];
                states = 2;
              } else if (_char === ' ') {
                object[functionName] = null;
                states = 0;
              } else throw new SyntaxError();

              break;

            case 2:
              if (ParameterChar.test(_char)) {
                parameter = _char;
                states = 3;
              } else if (_char != ' ') throw new SyntaxError();

              break;

            case 3:
              if (ParameterChar.test(_char)) {
                parameter = parameter.concat(_char);
              } else if (_char === ',') {
                object[functionName].push(parameter);
                states = 2;
              } else if (_char === ')') {
                object[functionName].push(parameter);
                states = 0;
              } else if (_char === ' ') states = 4;else throw new SyntaxError();

              break;

            case 4:
              if (_char === ',') {
                object[functionName].push(parameter);
                states = 2;
              } else if (_char === ')') {
                object[functionName].push(parameter);
                states = 0;
              } else if (_char != ' ') throw new SyntaxError();

              break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return check(object);
    };

    var valueCheck = {
      number: function number(value) {
        if (!numberRegex.test(value)) throw new TypeError();
        return {
          type: 'number',
          value: parseFloat(value),
          unit: ''
        };
      },
      angle: function angle(value) {
        if (!angleRegex.test(value)) throw new TypeError();
        return {
          type: 'angle',
          value: parseFloat(valueRegex.exec(value)[0]),
          unit: unitRegex.exec(value)[0]
        };
      },
      length: function length(value) {
        if (!lengthRegex.test(value)) throw new TypeError();
        return {
          type: 'length',
          value: parseFloat(valueRegex.exec(value)[0]),
          unit: unitRegex.exec(value)[0]
        };
      }
    };
    var functionCheck = {
      matrix: function matrix(parameters) {
        if (parameters === null | parameters.length != 6) throw new SyntaxError();
        return {
          a: valueCheck.number(parameters[0]),
          b: valueCheck.number(parameters[1]),
          c: valueCheck.number(parameters[2]),
          d: valueCheck.number(parameters[3]),
          tx: valueCheck.number(parameters[4]),
          ty: valueCheck.number(parameters[5])
        };
      },
      translate: function translate(parameters) {
        if (parameters === null | parameters.length < 1 | parameters.length > 2) throw new SyntaxError();
        var tx = valueCheck.length(parameters[0]);
        return {
          tx: tx,
          ty: parameters.length === 2 ? valueCheck.length(parameters[1]) : tx
        };
      },
      translateX: function translateX(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          t: valueCheck.length(parameters[0])
        };
      },
      translateY: function translateY(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          t: valueCheck.length(parameters[0])
        };
      },
      scale: function scale(parameters) {
        if (parameters === null | parameters.length < 1 | parameters.length > 2) throw new SyntaxError();
        var sx = valueCheck.number(parameters[0]);
        return {
          sx: sx,
          sy: parameters.length === 2 ? valueCheck.number(parameters[1]) : sx
        };
      },
      scaleX: function scaleX(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          s: valueCheck.number(parameters[0])
        };
      },
      scaleY: function scaleY(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          s: valueCheck.number(parameters[0])
        };
      },
      rotate: function rotate(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          a: valueCheck.angle(parameters[0])
        };
      },
      skew: function skew(parameters) {
        if (parameters === null | parameters.length < 1 | parameters.length > 2) throw new SyntaxError();
        var ax = valueCheck.angle(parameters[0]);
        return {
          ax: ax,
          ay: parameters.length === 2 ? valueCheck.angle(parameters[1]) : ax
        };
      },
      skewX: function skewX(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          a: valueCheck.angle(parameters[0])
        };
      },
      skewY: function skewY(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          a: valueCheck.angle(parameters[0])
        };
      },
      matrix3d: function matrix3d(parameters) {
        if (parameters === null | parameters.length != 16) throw new SyntaxError();
        return {
          a1: valueCheck.number(parameters[0]),
          b1: valueCheck.number(parameters[1]),
          c1: valueCheck.number(parameters[2]),
          d1: valueCheck.number(parameters[3]),
          a2: valueCheck.number(parameters[4]),
          b2: valueCheck.number(parameters[5]),
          c2: valueCheck.number(parameters[6]),
          d2: valueCheck.number(parameters[7]),
          a3: valueCheck.number(parameters[8]),
          b3: valueCheck.number(parameters[9]),
          c3: valueCheck.number(parameters[10]),
          d3: valueCheck.number(parameters[11]),
          a4: valueCheck.number(parameters[12]),
          b4: valueCheck.number(parameters[13]),
          c4: valueCheck.number(parameters[14]),
          d4: valueCheck.number(parameters[15])
        };
      },
      translate3d: function translate3d(parameters) {
        if (parameters === null | parameters.length != 3) throw new SyntaxError();
        return {
          tx: valueCheck.length(parameters[0]),
          ty: valueCheck.length(parameters[1]),
          tz: valueCheck.length(parameters[2])
        };
      },
      translateZ: function translateZ(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          t: valueCheck.length(parameters[0])
        };
      },
      scale3d: function scale3d(parameters) {
        if (parameters === null | parameters.length != 3) throw new SyntaxError();
        return {
          sx: valueCheck.number(parameters[0]),
          sy: valueCheck.number(parameters[1]),
          sz: valueCheck.number(parameters[2])
        };
      },
      scaleZ: function scaleZ(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          s: valueCheck.number(parameters[0])
        };
      },
      rotate3d: function rotate3d(parameters) {
        if (parameters === null | parameters.length != 4) throw new SyntaxError();
        return {
          x: valueCheck.number(parameters[0]),
          y: valueCheck.number(parameters[1]),
          z: valueCheck.number(parameters[2]),
          a: valueCheck.angle(parameters[3])
        };
      },
      rotateX: function rotateX(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          a: valueCheck.angle(parameters[0])
        };
      },
      rotateY: function rotateY(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          a: valueCheck.angle(parameters[0])
        };
      },
      rotateZ: function rotateZ(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          a: valueCheck.angle(parameters[0])
        };
      },
      perspective: function perspective(parameters) {
        if (parameters === null | parameters.length != 1) throw new SyntaxError();
        return {
          l: valueCheck.length(parameters[0])
        };
      }
    };

    function check(object) {
      var _object = {};

      for (var functionName in object) {
        _object[functionName] = functionCheck[functionName](object[functionName]);
      }

      return _object;
    }
  }

  _createClass(TransformFunctionsInterpreter, null, [{
    key: "getAngleValue",
    value: function getAngleValue(angleObject) {
      if (angleObject.type != 'angle') throw new RangeError();

      switch (angleObject.unit) {
        case 'deg':
          return angleObject.value * Math.PI / 180;

        case 'grad':
          return angleObject.value * Math.PI / 200;

        case 'rad':
          return angleObject.value;

        case 'turn':
          return angleObject.value * 2 * Math.PI;

        default:
          throw new RangeError();
      }
    }
  }]);

  return TransformFunctionsInterpreter;
}();

var _default = TransformFunctionsInterpreter;
exports["default"] = _default;