"use strict";

require("core-js/modules/es.symbol");

require("core-js/modules/es.symbol.description");

require("core-js/modules/es.symbol.iterator");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.array-buffer.slice");

require("core-js/modules/es.date.to-string");

require("core-js/modules/es.object.create");

require("core-js/modules/es.object.get-prototype-of");

require("core-js/modules/es.object.set-prototype-of");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.reflect.construct");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.iterator");

require("core-js/modules/es.typed-array.float32-array");

require("core-js/modules/es.typed-array.copy-within");

require("core-js/modules/es.typed-array.every");

require("core-js/modules/es.typed-array.fill");

require("core-js/modules/es.typed-array.filter");

require("core-js/modules/es.typed-array.find");

require("core-js/modules/es.typed-array.find-index");

require("core-js/modules/es.typed-array.for-each");

require("core-js/modules/es.typed-array.includes");

require("core-js/modules/es.typed-array.index-of");

require("core-js/modules/es.typed-array.iterator");

require("core-js/modules/es.typed-array.join");

require("core-js/modules/es.typed-array.last-index-of");

require("core-js/modules/es.typed-array.map");

require("core-js/modules/es.typed-array.reduce");

require("core-js/modules/es.typed-array.reduce-right");

require("core-js/modules/es.typed-array.reverse");

require("core-js/modules/es.typed-array.set");

require("core-js/modules/es.typed-array.slice");

require("core-js/modules/es.typed-array.some");

require("core-js/modules/es.typed-array.sort");

require("core-js/modules/es.typed-array.subarray");

require("core-js/modules/es.typed-array.to-locale-string");

require("core-js/modules/es.typed-array.to-string");

require("core-js/modules/web.dom-collections.for-each");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _generalCanvasBaseRenderer = _interopRequireDefault(require("./generalCanvasBaseRenderer"));

var _browserNotSupportError = _interopRequireDefault(require("../errors/browserNotSupportError"));

var _generalVertexShader = _interopRequireDefault(require("./glsl/generalVertexShader"));

var _generalFragmentShader = _interopRequireDefault(require("./glsl/generalFragmentShader"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf(subClass, superClass); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _possibleConstructorReturn(self, call) { if (call && (_typeof(call) === "object" || typeof call === "function")) { return call; } return _assertThisInitialized(self); }

function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }

/**
 * WebGL 渲染器类
 */
var GeneralWebglRenderer = function (_GeneralCanvasBaseRen) {
  _inherits(GeneralWebglRenderer, _GeneralCanvasBaseRen);

  var _super = _createSuper(GeneralWebglRenderer);

  /**
   * 实例化一个 WebGL 渲染器类
   * @param {object} element - Element 元素
   * @param {openBSE~Options} options - 全局选项
   * @param {object} elementSize - 元素大小
   * @param {Event} eventTrigger - 事件引发方法
   * @throws {openBSE.BrowserNotSupportError} 浏览器不支持特定渲染模式时引发错误
   */
  function GeneralWebglRenderer(element, options, elementSize, eventTrigger) {
    var _this;

    _classCallCheck(this, GeneralWebglRenderer);

    supportCheck();
    _this = _super.call(this, element, options, elementSize, eventTrigger);
    /**
     * 屏幕上的弹幕
     * @private @type {LinkedList}
     */

    var _bulletScreensOnScreen = _this.getBulletScreensOnScreen();
    /**
     * WebGL 上下文对象
     * @private @type {object}
     */


    var _webglContext;

    var _resolutionUniformLocation;

    var _scleUniformLocation;
    /**
     * Canvas 元素
     * @private @type {object}
     */


    var _canvas = _this.getCanvas();

    init.call(_assertThisInitialized(_this));
    var _cleanScreen = _this.cleanScreen;
    /**
     * 清除屏幕内容
     * @override
     */

    _this.cleanScreen = function () {
      _cleanScreen();

      _webglContext.clear(_webglContext.COLOR_BUFFER_BIT);
    };
    /**
     * 绘制函数
     * @override
     */


    _this.draw = function () {
      var _this2 = this;

      _webglContext.clear(_webglContext.COLOR_BUFFER_BIT);

      _bulletScreensOnScreen.forEach(function (node) {
        var realTimeBulletScreen = node.element;
        if (_this2.checkWhetherHide(realTimeBulletScreen)) return;
        var x1 = realTimeBulletScreen.x - 4;
        var x2 = x1 + realTimeBulletScreen.width + 8;
        var y1 = realTimeBulletScreen.actualY - 4;
        var y2 = y1 + realTimeBulletScreen.height + 8;

        _webglContext.bufferData(_webglContext.ARRAY_BUFFER, new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]), _webglContext.STATIC_DRAW);

        _webglContext.bindTexture(_webglContext.TEXTURE_2D, realTimeBulletScreen.texture2D);

        _webglContext.drawArrays(_webglContext.TRIANGLES, 0, 6);
      }, true);
    };

    var _creatAndgetWidth = _this.creatAndgetWidth;
    /**
     * 创建弹幕元素
     * @override
     * @param {object} realTimeBulletScreen - 实时弹幕对象
     */

    _this.creatAndgetWidth = function (realTimeBulletScreen) {
      _creatAndgetWidth(realTimeBulletScreen);

      var texture = _webglContext.createTexture();

      _webglContext.bindTexture(_webglContext.TEXTURE_2D, texture);

      _webglContext.texParameteri(_webglContext.TEXTURE_2D, _webglContext.TEXTURE_MIN_FILTER, _webglContext.NEAREST);

      _webglContext.texParameteri(_webglContext.TEXTURE_2D, _webglContext.TEXTURE_MAG_FILTER, _webglContext.NEAREST);

      _webglContext.texParameteri(_webglContext.TEXTURE_2D, _webglContext.TEXTURE_WRAP_S, _webglContext.CLAMP_TO_EDGE);

      _webglContext.texParameteri(_webglContext.TEXTURE_2D, _webglContext.TEXTURE_WRAP_T, _webglContext.CLAMP_TO_EDGE);

      _webglContext.texImage2D(_webglContext.TEXTURE_2D, 0, _webglContext.RGBA, _webglContext.RGBA, _webglContext.UNSIGNED_BYTE, realTimeBulletScreen.hideCanvas);

      realTimeBulletScreen.hideCanvas = null;
      realTimeBulletScreen.texture2D = texture;
    };

    var _setSize = _this.setSize;
    /**
     * 设置尺寸
     * @override
     */

    _this.setSize = function () {
      _setSize();

      _webglContext.viewport(0, 0, _canvas.width, _canvas.height);

      _webglContext.uniform2f(_resolutionUniformLocation, _canvas.width, _canvas.height);

      _webglContext.uniform1f(_scleUniformLocation, this.getScale());
    };
    /**
     * 初始化
     */


    function init() {
      var createShader = function createShader(gl, type, source) {
        var shader = gl.createShader(type);
        gl.shaderSource(shader, source);
        gl.compileShader(shader);
        var success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);

        if (success) {
          return shader;
        }

        gl.deleteShader(shader);
      };

      var createAndUseProgram = function createAndUseProgram(gl, VertexShaderSource, FragmentShaderSource) {
        var vertexShader = createShader(gl, gl.VERTEX_SHADER, VertexShaderSource);
        var fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, FragmentShaderSource);
        var program = gl.createProgram();
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        var success = gl.getProgramParameter(program, gl.LINK_STATUS);

        if (success) {
          gl.useProgram(program);
          return program;
        }

        gl.deleteProgram(program);
        return null;
      };

      _webglContext = _canvas.getContext('webgl');

      _webglContext.enable(_webglContext.BLEND);

      _webglContext.clearColor(0, 0, 0, 0);

      _webglContext.pixelStorei(_webglContext.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

      _webglContext.blendFunc(_webglContext.ONE, _webglContext.ONE_MINUS_SRC_ALPHA);

      _webglContext.viewport(0, 0, _canvas.width, _canvas.height);

      var program = createAndUseProgram(_webglContext, _generalVertexShader["default"], _generalFragmentShader["default"]);
      {
        _resolutionUniformLocation = _webglContext.getUniformLocation(program, 'u_resolution');

        _webglContext.uniform2f(_resolutionUniformLocation, _canvas.width, _canvas.height);
      }
      {
        _scleUniformLocation = _webglContext.getUniformLocation(program, 'u_scale');

        _webglContext.uniform1f(_scleUniformLocation, this.getScale());
      }
      {
        var positionBuffer = _webglContext.createBuffer();

        _webglContext.bindBuffer(_webglContext.ARRAY_BUFFER, positionBuffer);

        var texcoordAttributeLocation = _webglContext.getAttribLocation(program, 'a_texcoord');

        _webglContext.enableVertexAttribArray(texcoordAttributeLocation);

        _webglContext.vertexAttribPointer(texcoordAttributeLocation, 2, _webglContext.FLOAT, false, 0, 0);

        _webglContext.bufferData(_webglContext.ARRAY_BUFFER, new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]), _webglContext.STATIC_DRAW);
      }
      {
        var _positionBuffer = _webglContext.createBuffer();

        _webglContext.bindBuffer(_webglContext.ARRAY_BUFFER, _positionBuffer);

        var positionAttributeLocation = _webglContext.getAttribLocation(program, 'a_position');

        _webglContext.enableVertexAttribArray(positionAttributeLocation);

        _webglContext.vertexAttribPointer(positionAttributeLocation, 2, _webglContext.FLOAT, false, 0, 0);
      }
    }
    /**
     * 浏览器支持检测
     * @throws {openBSE.BrowserNotSupportError} 浏览器不支持特定渲染模式时引发错误
     */


    function supportCheck() {
      var canvas = document.createElement('canvas');
      if (typeof canvas.getContext != 'function') throw new _browserNotSupportError["default"]('Canvas');
      var context = canvas.getContext('2d');
      if (context === null) throw new _browserNotSupportError["default"]('Canvas 2D');
      if (typeof context.fillText != 'function') throw new _browserNotSupportError["default"]('Canvas 2D fillText Function');
      canvas = document.createElement('canvas');
      context = canvas.getContext('webgl');
      if (context === null) throw new _browserNotSupportError["default"]('WebGL');
    }

    return _this;
  }

  return GeneralWebglRenderer;
}(_generalCanvasBaseRenderer["default"]);

var _default = GeneralWebglRenderer;
exports["default"] = _default;