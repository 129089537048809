"use strict";

require("core-js/modules/es.symbol");

require("core-js/modules/es.symbol.description");

require("core-js/modules/es.symbol.iterator");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.date.to-string");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.string.iterator");

require("core-js/modules/web.dom-collections.iterator");

require("core-js/modules/web.timers");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.performance_now = exports.cancelAnimationFrame = exports.requestAnimationFrame = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * performance_now 定义（一些老式浏览器不支持 performance.now ）
 * @param {function} fun - 回调方法 
 * @function
*/
var performance_now;
/**
 * requestAnimationFrame 定义（一些老式浏览器不支持 requestAnimationFrame ）
 * @param {function} fun - 回调方法 
 * @function
*/

exports.performance_now = performance_now;
var requestAnimationFrame;
/**
 * cancelAnimationFrame 定义（一些老式浏览器不支持 cancelAnimationFrame ）
 * @param {function} fun - 回调方法 
 * @function
*/

exports.requestAnimationFrame = requestAnimationFrame;
var cancelAnimationFrame;
exports.cancelAnimationFrame = cancelAnimationFrame;

if (_typeof(window.performance) === 'object' && typeof window.performance.now === 'function') {
  exports.performance_now = performance_now = function performance_now() {
    return window.performance.now();
  };
} else {
  console.warn(Resources.PREFORMANCENOW_NOT_SUPPORT_WARN);

  exports.performance_now = performance_now = function performance_now() {
    return new window.Date().getTime();
  };
}

if (typeof window.requestAnimationFrame === 'function' && typeof window.cancelAnimationFrame === 'function') {
  exports.requestAnimationFrame = requestAnimationFrame = window.requestAnimationFrame;
  exports.cancelAnimationFrame = cancelAnimationFrame = window.cancelAnimationFrame;
} else {
  console.warn(Resources.REQUESTANIMATIONFRAME_NOT_SUPPORT_WARN);

  exports.requestAnimationFrame = requestAnimationFrame = function requestAnimationFrame(callback) {
    return window.setTimeout(function () {
      callback(performance_now());
    }, 17);
  };

  exports.cancelAnimationFrame = cancelAnimationFrame = function cancelAnimationFrame(handle) {
    return window.clearTimeout(handle);
  };
}