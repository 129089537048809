"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _resources = _interopRequireDefault(require("../lib/resources"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * 渲染器
 * @private @constant
 */
var RENDERERS = {
  /**
   * CSS3 渲染模式（普通弹幕引擎和特殊弹幕引擎）
   * @private @readonly
   */
  css3: {
    general: require('./generalCss3Renderer')["default"],
    special: require('./specialCss3Renderer')["default"]
  },

  /**
   * SVG 渲染模式（仅普通弹幕引擎）
   * @private @readonly
   */
  svg: {
    general: require('./generalSvgRenderer')["default"]
  },

  /**
   * WebGL 渲染模式（仅普通弹幕引擎）
   * @private @readonly
   */
  webgl: {
    general: require('./generalWebglRenderer')["default"]
  },

  /**
   * Canvas 2D 渲染模式（普通弹幕引擎和特殊弹幕引擎）
   * @private @readonly
   */
  canvas: {
    general: require('./generalCanvasRenderer')["default"],
    special: require('./specialCanvasRenderer')["default"]
  }
};
/**
 * 渲染器工厂
 */

var RenderersFactory =
/**
 * 实例化一个渲染器工厂
 * @param {object} element - Element 元素
 * @param {openBSE~Options} options - 全局选项
 * @param {object} elementSize - 元素大小
 * @param {Event} eventTrigger - 事件引发方法
 */
function RenderersFactory(element, options, elementSize, eventTrigger) {
  var _this = this;

  _classCallCheck(this, RenderersFactory);

  /**
   * 获取渲染器
   * @param {string} renderMode - 渲染模式
   * @param {string} engineType - 引擎类型
   * @returns {BaseRenderer} 渲染器的实例
   * @throws {openBSE.BrowserNotSupportError} 浏览器不支持特定渲染模式时引发错误
   * @throws {TypeError} 传入的参数错误时引发错误。请参阅 MDN [TypeError]{@link https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/TypeError} 。
   */
  this.getRenderer = function (renderMode, engineType) {
    var renderer = RENDERERS[renderMode][engineType];
    if (typeof renderer === 'undefined') throw new TypeError(_resources["default"].RENDER_MODE_ERROR.fillData({
      renderMode: renderMode
    }));
    return new renderer(element, options, elementSize, eventTrigger);
  },
  /**
   * 获取普通弹幕渲染器
   * @param {string} renderMode - 渲染模式
   * @returns {BaseRenderer} 渲染器的实例
   * @throws {openBSE.BrowserNotSupportError} 浏览器不支持特定渲染模式时引发错误
   * @throws {TypeError} 传入的参数错误时引发错误。请参阅 MDN [TypeError]{@link https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/TypeError} 。
   */
  this.getGeneralRenderer = function (renderMode) {
    return _this.getRenderer(renderMode, 'general');
  };
  /**
   * 获取特殊弹幕渲染器
   * @param {string} renderMode - 渲染模式
   * @returns {BaseRenderer} 渲染器的实例
   * @throws {openBSE.BrowserNotSupportError} 浏览器不支持特定渲染模式时引发错误
   * @throws {TypeError} 传入的参数错误时引发错误。请参阅 MDN [TypeError]{@link https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/TypeError} 。
   */

  this.getSpecialRenderer = function (renderMode) {
    return _this.getRenderer(renderMode, 'special');
  };
};

var _default = RenderersFactory;
exports["default"] = _default;